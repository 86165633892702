<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A
        <number-value :value="MnO2" unit="\text{g}" />
        portion of manganese (IV) oxide
        <chemical-latex content="(MnO2)" />
        was added to an acidic solution in which
        <number-value :value="Cl" unit="\text{g}" />
        of a chloride
        <chemical-latex content="(Cl-)" />
        containing sample was dissolved. Evolution of chlorine gas took place as a consequence of
        the following reaction:
      </p>
      <p>
        <chemical-latex content="MnO2(s) + 2Cl- + 4H+ -> Mn^{2+} + Cl2(g) +	2H2O" />
      </p>
      <p>
        After the reaction was complete, the excess
        <chemical-latex content="MnO2" />
        was collected by filtration, washed, and weighed, and
        <number-value :value="recoveredMnO2" unit="\text{g}" />
        was recovered. Express the results of this analysis in terms of mass percent of aluminum
        chloride
        <chemical-latex content="(AlCl3)\text{.}" />
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        append-text="$\ce{\% AlCl_3}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUPEI2210A3_Q4',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    MnO2() {
      return this.taskState.getValueBySymbol('MnO2').content;
    },
    recoveredMnO2() {
      return this.taskState.getValueBySymbol('recoveredMnO2').content;
    },
    Cl() {
      return this.taskState.getValueBySymbol('Cl').content;
    },
  },
};
</script>
